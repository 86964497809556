
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-smartlokus-reload-primary: mat-palette($mat-indigo);
$web-smartlokus-reload-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-smartlokus-reload-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-smartlokus-reload-theme: mat-light-theme((
  color: (
    primary: $web-smartlokus-reload-primary,
    accent: $web-smartlokus-reload-accent,
    warn: $web-smartlokus-reload-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($web-smartlokus-reload-theme);

/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import "~owl-ng/assets/style/theme.min.css";

// custom style definition
@import 'procel.scss';
@import 'tables.scss';


// Set the full page height so we can stick the footer to the bottom
html,
body {
  height: 100%;
}

.p-6 {
  padding: ($spacer * 5) !important;
}
.p-7 {
  padding: ($spacer * 7) !important;
}
.p-8 {
  padding: ($spacer * 9) !important;
}
.p-9 {
  padding: ($spacer * 11) !important;
}
.p-10 {
  padding: ($spacer * 14) !important;
}

footer.page-footer .footer-copyright {
  //position: fixed;
  //bottom: 0;
  //width: 100%;
  background: rgba(255, 255, 255, 0) !important;
  //z-index:9999999;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: contain;
}

.bc-icons-2 .breadcrumb-item+.breadcrumb-item::before {
  content: none;
}

.bc-icons-2 {
  mdb-breadcrumb {
    mdb-breadcrumb-item.active {
      .breadcrumb-item {
        color: #000 !important;
      }
    }
  }
}

agm-map {
  height: 600px;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

