.top-nav__logo {
    max-width: 110px;
}

.logo {
    width: 150px;
}

p {
    margin-bottom: 0px;
}

p.caption {
    font-size: 14px;
    line-height: 18px;
}

.top-nav {
    height: 80px;
    justify-content: space-between;
    background-color: #0067d0;
    color: #fff;
    border-bottom: 8px solid #fe6601;
    padding: 0 36px;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

.header__content {
    max-width: 700px;
    margin: auto;
}

.header__title {
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: .3px;
    color: #333;
    //display: inline;
    background-color: hsla(0,0%,100%,.6)
}

.header {
    background: url(../img/landing/background.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 150%;
    background-size: cover;
    padding: 90px;
}

.content__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
    margin-bottom: 18px;
}

.content__subtitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 18px;
}

.feature__ico {
    height: 70px;
    width: 70px;
    margin-right: 18px;
    margin-top: 4px;
    float: left;
}

.feature__extra img {
    max-width: 100%;
    line-height: 1.15;
}

.feature__title__1 {
    font-size: 26px;
    line-height: 25px;
    margin-bottom: 18px;
}

.feature__title__2 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 18px;
}

.feature__subtitle {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 18px;
}

.version_negrita {
    font-size: 15px;
    font-weight: bold;
}

/***********************
Nuevos CSS G-Talent
***********************/

/* scrollbar */
body {
    overflow-x: hidden;
    overflow-y: auto;
}

/* login */
.card.form-login {
    border: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    font-weight: 400;
}

.card-body.form-login {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.form-login .btn-info {
    background-color: #0067d0;
    border-color: #0067d0;
}

.form-login .btn-info:hover,
.form-login .btn-info:active,
.form-login .btn-info:focus,
.form-login .btn-info:visited {
    background-color: #015dbb !important;
    border-color: #015dbb !important;
}

.form-login .btn-info:disabled {
    background-color: #0067d0;
    border-color: #0067d0;
}

 .height_login {
    margin: 4% 0;
 }

@media (min-width: 1400px) {
    .height_login {
        margin: 15% 0;
     }
}

/* home */
.header__title {
    font-size: 36px !important;
    line-height: 36px;
    color: #0067d0;
}

.home_ h2 {
    color: #424242;
    font-size: 30px !important;
}

.home_ .feature__title__2 {
    font-weight: 500;
    margin-top: 25px;
    font-size: 1.3rem !important;
}

.home_ p {
    color: #424242;
    font-size: 1.2rem !important;
    font-weight: 300;
}

.feature__subtitle {
    margin-top: 45px
}

/* header */
.top-nav__logo {
    max-width: 150px;
}

nav .btn-header {
    background-color: #fe6601;
    border-color: #fe6601;
    color: #fff;    
    padding: 6px 30px !important;
}

nav .btn-header:hover,
nav .btn-header:active,
nav .btn-header:focus,
nav .btn-header:visited {
    background-color: #e75f05;
    border-color: #e75f05;
    color: #fff;
    font-weight: 400 !important;
}

/* footer */
footer .bg-warning {
    background-color: #fe6601 !important;
}

/* márgenes main */
main.m-2 {
    margin: 0 !important
} 

/* sidebar */
app-navbar {
    background-color: #dce9f5;
    width: 180px;
    box-shadow: 0 0 5px -2px rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 0 !important;
}
.sidebar_ {
    margin-top: 20px !important;
}

.sidebar_ .nav-link {
    border-bottom: solid 1px #0067d0 !important;
    border-radius: 0 !important;
    min-width: 145px;
    color: #0067d0;
}

.sidebar_ .nav-link:hover {
    color: #fe6601;
}

/* Información reporte */
.table-report table {
    font-family: Arial, Verdana, Times; 
    font-size: 12px; 
    text-align: left; 
    border-spacing: 0px;
}

.title-report {
    text-align: center;
    font-weight: bold;
    background: #0067d0;
    color: #FFF;
}

.table-report td {
    padding: 3px !important;
}

.si-content {
    padding: 0 0 10px 10px;
}

.si-close-button {
    margin-top: 7px !important;
    margin-right: 10px !important;
    color: #fff !important;
}

.si-content button {
    border: 1px solid #0067d0;
    background: #eef6ff;
}

.si-content button:hover {
    border: 1px solid #0067d0;
    background: #dee2e6;
}

/* Fondo sitio */
.fondo_color {
    background-color: #f5f5f5;
}

.fondo_padding {
    padding: 20px; 
}

/* Calugas proyectos */
.btn-calugas_adm {
    text-align: left !important;
    padding: 0 !important;
}

.mt_adm {
    margin-top: -38px;
}

.btn-calugas {
    height: 220px;
    text-align: left !important;
    padding: 0 20px !important;
}

.btn-calugas2 {
    height: auto;
    text-align: left !important;
    padding: 0 20px !important;
}

.btn-calugas3 {
    text-align: center !important;
    padding: 0 20px !important;
}


.btn-calugas span,
.btn-calugas2 span,
.btn-calugas3 {
    text-transform: uppercase;
}

.btn-calugas b,
.btn-calugas2 b {
    color: #212529;
}

.hover_caluga:hover {
    margin-top: 6px;
}

/* btn volver atrás */
.btn-atras {
    background-color: #f5f5f5 !important;
    border-color: #0067d0 !important;
    color: #0067d0 !important;    
    padding: 8px 25px !important;
}

.btn-atras:hover,
.btn-atras:active,
.btn-atras:focus,
.btn-atras:visited {
    background-color: #035db7 !important;
    border-color: #035db7 !important;
    color: #fff !important;
    font-weight: 400 !important;
}

/* color de fondo header tablas */
table th {
    background: #dce9f5;
    color: #0067d0 !important;
}

/* estilo pantalla reporte */
.title-reporte {
    background-color: #0067d0;
    color: #fff;
}

.title-reporte span {
    font-weight: 400;
    font-size: 1rem;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.30) !important;
}

.margin-btn {
    margin: 0 auto !important;
}

/* estilos calendario */
.mat-calendar-table th {
    background: #fff;
    color: #0067d0 !important;
}

.mat-calendar-body-selected {
    background-color: #0067d0;
    color: white;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #0067d0;
    color: #0067d0;
}

.form-control-plaintext {
    font-weight: 100 !important
}

.espacio_cliente {
    text-align: left !important;
    padding-left: 0 !important;
}

.mt-infra {
    margin-top: -15px;
}

.mt_nombreProyecto {
    margin-top: 30px;
}

.reporte_listadoTable tr th {
    min-width: 4rem !important;
}

.text_card span {
    font-weight: 100;
}


/* ancho selet */
.mat-select-panel {
    overflow-x: hidden !important;
    max-width: 100% !important;
}

.width_select {
    min-width: auto !important;
}

/* Tabla Responsive */

.div-tb_100 {
    width: 100%;
    background: #fff;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 15px;
}

@media (max-width: 1400px) {
    .div-tb_100 {
        overflow-x: auto;
        max-width: 1100px !important;
    }
}

@media (min-width: 1400px) {
    .div-tb_100 {
        overflow-x: auto;
        max-width: 1600px !important;
    }
}